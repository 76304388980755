import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Fachbetrieb } from '@/js/model'
import { useFachbetriebService } from '@/services'

export const useFachbetriebStore = defineStore('fachbetrieb', () => {
    const fachbetriebe = ref<Fachbetrieb[]>([])
    const service = useFachbetriebService()

    const fetchFachbetriebe = async () => {
        const result = await service.getAllFachbetriebe()
        fachbetriebe.value = result['hydra:member']
    }

    return { fachbetriebe, fetchFachbetriebe }
})
