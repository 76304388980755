<script lang="ts" setup>
    import { ref, watch, type Ref, onBeforeMount } from 'vue'
    import { useBetriebspruefungService } from '@/services'
    import { type Betriebsbeauftragter, type Betriebspruefung } from '@/js/model'
    import { useAlertStore, useFachprueferStore } from '@/stores'
    import {
        itemsPerPageOptions,
        itemsPerPageText,
        formatDateTime,
        formatDate,
        type TableHeaders,
        type SortItem,
        noItemsText,
        loadingText,
        type FetchParams
    } from '@/utils'
    import {
        BooleanCell,
        ChangeKontaktOfBetriebsbeauftragterCell,
        EmailCell,
        TelefonnummerCell,
        TerminAmCell
    } from '@/components'

    const alertStore = useAlertStore()
    const itemsPerPage = ref<number>(25)
    const headers = ref<TableHeaders>([
        { title: 'Mitglieds-nummer', align: 'start', key: 'mitgliedsnummer' },
        { title: 'Firma', align: 'start', key: 'firma.nameOne' },
        { title: 'Straße', align: 'start', key: 'adresseFachbetrieb.strasse' },
        { title: 'PLZ', align: 'start', key: 'adresseFachbetrieb.postleitzahl' },
        { title: 'Ort', align: 'start', key: 'adresseFachbetrieb.ort' },
        { title: 'Telefon', align: 'start', key: 'kontaktFachbetrieb', sortable: false },
        { title: 'E-Mail', align: 'start', key: 'kontaktFachbetrieb.email', sortable: false },
        {
            title: 'Betriebsbeauftragter',
            align: 'start',
            key: 'betriebsbeauftragterId',
            sortable: false
        },
        {
            title: 'Ablauf Urkunde',
            align: 'start',
            key: 'zertifiziertBis',
            sortable: true,
            value: (item) => `${formatDate(item.zertifiziertBis)}`
        },
        {
            title: 'Prüfungstermin',
            align: 'center',
            children: [
                {
                    title: '',
                    align: 'center',
                    key: 'terminAm',
                    sortable: true,
                    value: (item: Betriebspruefung) => `${formatDate(item.terminAm)}`
                },
                {
                    title: 'angefragt?',
                    align: 'center',
                    key: 'isTerminAngefragt',
                    sortable: true
                },
                {
                    title: 'bestätigt?',
                    align: 'center',
                    key: 'isTerminBestaetigt',
                    sortable: true
                }
            ]
        },
        // {
        //     title: 'Mangel letzte Prüfung',
        //     align: 'start',
        //     key: 'mangelDerPruefung.mangelKey',
        //     value: (item) => item.mangelDerPruefung.mangel
        // },
        { title: 'Bemerkungen', align: 'start', key: 'bemerkung', sortable: false },
        {
            title: 'Letzte Änderung',
            align: 'start',
            key: 'updatedAt',
            value: (item) => `${formatDateTime(item.updatedAt)}`
        }
    ])

    const serverItems: Ref<Betriebspruefung[]> = ref([])
    const tableOptions = ref<{ sortBy: SortItem[]; page?: number; itemsPerPage?: number }>({
        sortBy: []
    })
    const loading = ref<boolean>(true)
    const totalItems = ref<number>(0)
    const mitgliedsnummer = ref<string | null>(null)
    const search = ref<string>('')
    const selectedYear = ref(new Date().getFullYear())

    const betriebspruefungService = useBetriebspruefungService()
    const fachprueferStore = useFachprueferStore()

    const dateList = () => {
        const currentYear = new Date().getFullYear()

        const lastYear = new Date(currentYear - 1, 0, 1).getFullYear()
        const nextYear = new Date(currentYear + 1, 0, 1).getFullYear()

        return [lastYear, currentYear, nextYear]
    }

    const onItemChanged = (newItem: Betriebspruefung) => {
        const index = serverItems.value.findIndex((item) => item['@id'] === newItem['@id'])

        if (index !== -1) {
            serverItems.value.splice(index, 1, newItem)
        } else {
            const message = 'Beim Ändern des Termins ist etwas schiefgelaufen.'
            alertStore.error(message)
            throw new Error(message)
        }
    }

    const onBetriebsbeauftragterChanged = (betriebsbeauftragter: Betriebsbeauftragter['@id']) => {
        /* TODO: refresh betriebspruefung because kontaktOfBetriebsbeauftragter is on betriebspruefung
        /*      maybe betriebspruefung should be in store as own list; e.g. "tableList"?
        */
        console.log('onBetriebsbeauftragterChanged')
        console.log(betriebsbeauftragter)
    }

    const loadItems = async ({
        page,
        itemsPerPage,
        sortBy
    }: FetchParams & {
        sortBy: SortItem[]
    }) => {
        loading.value = true
        tableOptions.value = {
            page,
            itemsPerPage,
            sortBy
        }
        let data: FetchParams = {
            page,
            itemsPerPage,
            mitgliedsnummer: mitgliedsnummer.value
        }

        if (selectedYear.value) {
            data['zertifiziertBis[after]'] = new Date(selectedYear?.value, 0, 1)?.toISOString()
            data['zertifiziertBis[before]'] = new Date(selectedYear?.value, 11, 31)?.toISOString()
        }

        if (sortBy[0]) {
            sortBy.forEach((val: SortItem) => (data[`order[${val.key}]`] = val.order))
        }

        try {
            const result = await betriebspruefungService.getAllBetriebspruefungen(data)
            const items = result['hydra:member']
            const total = result['hydra:totalItems']

            serverItems.value = items
            totalItems.value = total ?? 0
            loading.value = false
        } catch (e: unknown) {
            console.error(e)
            loading.value = false
        }
    }

    watch([mitgliedsnummer, selectedYear], () => {
        search.value = String(Date.now())
    })

    onBeforeMount(async () => {
        await Promise.all([fachprueferStore.fetchFachpruefende()])
    })
</script>

<template>
    <v-row class="pb-4">
        <v-col cols="12" md="3">
            <v-text-field
                v-model="mitgliedsnummer"
                density="compact"
                placeholder="Nach Mitgliedsnummer suchen..."
                append-inner-icon="mdi-magnify"
                hide-details
                clearable
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="3">
            <v-btn-toggle color="primary" v-model="selectedYear" group density="compact">
                <v-btn
                    v-for="date in dateList()"
                    :key="date"
                    :value="date"
                    :data-test="`filter-year-${date}`"
                    >{{ date }}</v-btn
                >
            </v-btn-toggle>
        </v-col>
    </v-row>
    <v-data-table-server
        v-model:items-per-page="itemsPerPage"
        :headers="headers"
        :items="serverItems"
        :items-length="totalItems"
        :items-per-page-options="itemsPerPageOptions"
        :items-per-page-text="itemsPerPageText"
        :loading="loading"
        :search="search"
        item-value="name"
        :no-data-text="noItemsText"
        :loading-text="loadingText"
        multi-sort
        @update:options="loadItems"
        density="compact"
    >
        <template v-slot:item.firma.nameOne="{ item }">
            {{ item.firma.name }}
        </template>
        <template v-slot:item.bemerkung="{ item }">
            <span class="preformatted-text">{{ item.bemerkung }}</span>
            <v-btn icon="mdi-pencil" size="small" variant="text"></v-btn>
        </template>
        <template v-slot:item.terminAm="{ item }">
            <TerminAmCell :betriebspruefung="item" @item-changed="onItemChanged"></TerminAmCell>
        </template>
        <template v-slot:item.isTerminAngefragt="{ item }">
            <BooleanCell :is-enabled="item.isTerminAngefragt" />
        </template>
        <template v-slot:item.isTerminBestaetigt="{ item }">
            <BooleanCell :is-enabled="item.isTerminBestaetigt" />
        </template>
        <template v-slot:item.betriebsbeauftragterId="{ item }">
            <ChangeKontaktOfBetriebsbeauftragterCell
                v-if="item.betriebsbeauftragterId"
                :betriebsbeauftragterId="item.betriebsbeauftragterId"
                @betriebsbeauftragter-changed="onBetriebsbeauftragterChanged"
            />
        </template>
        <template v-slot:item.kontaktFachbetrieb="{ item }">
            <template
                v-for="(telefonnummer, key) in item?.kontaktBetriebsbeauftragter?.telefon"
                :key="key"
            >
                <div class="d-flex ga-1" title="Betriebsbeauftragter">
                    <v-chip color="primary" variant="tonal" size="x-small">B:</v-chip>
                    <TelefonnummerCell :telefonnummer="telefonnummer" />
                </div>
            </template>
            <template v-for="(mobil, key) in item?.kontaktBetriebsbeauftragter?.mobil" :key="key">
                <div class="d-flex ga-1" title="Betriebsbeauftragter">
                    <v-chip color="primary" variant="tonal" size="x-small">B:</v-chip>
                    <TelefonnummerCell :telefonnummer="mobil" />
                </div>
            </template>
            <template v-for="(telefonnummer, key) in item?.kontaktFachbetrieb?.telefon" :key="key">
                <div class="d-flex ga-1" title="Fachbetrieb">
                    <v-chip color="primary" variant="tonal" size="x-small">FB:</v-chip>
                    <TelefonnummerCell :telefonnummer="telefonnummer" />
                </div>
            </template>
            <template v-for="(mobil, key) in item?.kontaktFachbetrieb?.mobil" :key="key">
                <div class="d-flex ga-1" title="Fachbetrieb">
                    <v-chip color="primary" variant="tonal" size="x-small">FB:</v-chip>
                    <TelefonnummerCell :telefonnummer="mobil" />
                </div>
            </template>
        </template>
        <template v-slot:item.kontaktBetriebsbeauftragter.mobil="{ item }">
            <div class="d-flex ga-1" title="Betriebsbeauftragter">
                <v-chip color="primary" closable class="ms-0 me-2" small>B</v-chip>
                <template
                    v-for="(mobil, key) in item?.kontaktBetriebsbeauftragter?.mobil"
                    :key="key"
                >
                    <TelefonnummerCell :telefonnummer="mobil" />
                </template>
            </div>
        </template>
        <template v-slot:item.kontaktFachbetrieb.email="{ item }">
            <template v-for="(email, key) in item?.kontaktFachbetrieb?.email" :key="key">
                <div class="d-flex ga-1" title="Fachbetrieb">
                    <v-chip color="primary" variant="tonal" size="x-small">FB:</v-chip>
                    <EmailCell :email="email" />
                </div>
            </template>
        </template>
    </v-data-table-server>
</template>

<style scoped>
    :deep(.v-select .v-field-label) {
        color: grey;
        opacity: 1;
    }

    :deep(.v-input input::placeholder) {
        color: grey;
        opacity: 1;
    }
</style>
