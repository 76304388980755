import type {
    BetriebsbeauftragteResponse,
    Betriebsbeauftragter,
    ChangeKontaktOfBetriebsbeauftragterRequest
} from '@/js/model'
import { type FetchParams, useFetch } from '@/utils'

export const useBetriebsbeauftragterService = () => {
    const fetch = useFetch({ baseURL: '/api/betriebsbeauftragte' })

    const getAllBetriebsbeauftragte = (fetchParams: FetchParams = {}) => {
        return fetch.get<BetriebsbeauftragteResponse>({ fetchParams })
    }

    const getBetriebsbeauftragterByIri = (iri: Betriebsbeauftragter['@id']) => {
        return useFetch({ baseURL: iri ?? '' }).get<Betriebsbeauftragter>({})
    }

    const getBetriebsbeauftragterById = (id: string) => {
        return getBetriebsbeauftragterByIri(`/api/betriebsbeauftragte/${id}`)
    }

    const changeKontaktOfBetriebsbeauftragter = async (
        request: ChangeKontaktOfBetriebsbeauftragterRequest
    ) => {
        return await useFetch({
            baseURL: '/api/betriebsbeauftragte/change-kontakt'
        }).post<Betriebsbeauftragter>({ body: request })
    }

    return {
        getAllBetriebsbeauftragte,
        getBetriebsbeauftragterById,
        getBetriebsbeauftragterByIri,
        changeKontaktOfBetriebsbeauftragter
    }
}
