import { defineStore } from 'pinia'
import { ref } from 'vue'
import type { Betriebsbeauftragter } from '@/js/model'
import { useBetriebsbeauftragterService } from '@/services'

export const useBetriebsbeauftragterStore = defineStore('betriebsbeauftragter', () => {
    const betriebsbeauftragte = ref<Betriebsbeauftragter[]>([])
    const service = useBetriebsbeauftragterService()

    const getFullNameById = (id?: string | null): string => {
        return (
            betriebsbeauftragte.value.find((item) => item.betriebsbeauftragterId === id)
                ?.fullName ?? ''
        )
    }

    const getBetriebsbeauftragterById = (id: string): Betriebsbeauftragter | null => {
        const betriebsbeauftragter = betriebsbeauftragte.value.find(
            (item) => item.betriebsbeauftragterId === id
        )
        if (betriebsbeauftragter) {
            return betriebsbeauftragter
        } else {
            return null
        }
    }

    const fetchBetriebsbeauftragterById = async (id: string): Promise<void> => {
        betriebsbeauftragte.value.push(await service.getBetriebsbeauftragterById(id))
    }

    const fetchBetriebsbeauftragterByIri = async (iri: string): Promise<void> => {
        betriebsbeauftragte.value.push(await service.getBetriebsbeauftragterByIri(iri))
    }

    return {
        betriebsbeauftragte,
        getBetriebsbeauftragterById,
        fetchBetriebsbeauftragterById,
        fetchBetriebsbeauftragterByIri,
        getFullNameById
    }
})
