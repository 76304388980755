<script setup lang="ts">
    import {
        type Betriebsbeauftragter,
        type ChangeKontaktOfBetriebsbeauftragterRequest
    } from '@/js/model'
    import { useBetriebsbeauftragterService } from '@/services'
    import { ref, onMounted, computed, defineEmits } from 'vue'
    import { useBetriebsbeauftragterStore } from '@/stores'
    import type { Kontakt } from '@/js/models'

    const props = defineProps<{
        betriebsbeauftragterId: Betriebsbeauftragter['betriebsbeauftragterId']
    }>()

    const emit = defineEmits<{
        betriebsbeauftragterChanged: [event: string]
    }>()

    const betriebsbeauftragterStore = useBetriebsbeauftragterStore()
    const betriebsbeauftragterService = useBetriebsbeauftragterService()

    const dialog = ref<boolean>(false)
    const kontakt = ref<Kontakt>({
        telefon: [],
        mobil: [],
        telefax: [],
        email: [],
        internet: ''
    })

    const isSubmitDisabled = computed(() => {
        if (!betriebsbeauftragter.value) {
            return true
        }
        return (
            kontakt.value.email[0] === betriebsbeauftragter.value.kontakt.email[0] &&
            kontakt.value.mobil[0] === betriebsbeauftragter.value.kontakt.mobil[0] &&
            kontakt.value.telefon[0] === betriebsbeauftragter.value.kontakt.telefon[0]
        )
    })
    const betriebsbeauftragter = computed(() => {
        return betriebsbeauftragterStore.getBetriebsbeauftragterById(props.betriebsbeauftragterId)
    })

    function setInitialValues() {
        if (betriebsbeauftragter.value && betriebsbeauftragter.value.kontakt) {
            // @chris: warum geht structuredClone() nicht?
            // kontakt.value = structuredClone(betriebsbeauftragter.value.kontakt)
            // oder sollten wir lodash verwenden?
            // import _ from 'lodash';
            //            kontakt.value = _.cloneDeep(betriebsbeauftragter.value.kontakt);
            // oder reicht Folgendes vollkommen aus und ist gängige best practice?
            kontakt.value = JSON.parse(JSON.stringify(betriebsbeauftragter.value.kontakt))
        }
    }

    onMounted(async () => {
        await betriebsbeauftragterStore.fetchBetriebsbeauftragterById(props.betriebsbeauftragterId)
        setInitialValues()
    })

    const handleChangeKontaktSubmit = async () => {
        const request: ChangeKontaktOfBetriebsbeauftragterRequest = {
            betriebsbeauftragter: `/api/betriebsbeauftragte/${props.betriebsbeauftragterId}`,
            kontakt: kontakt.value
        }

        const result =
            await betriebsbeauftragterService.changeKontaktOfBetriebsbeauftragter(request)

        if (result) {
            emit(
                'betriebsbeauftragterChanged',
                `/api/betriebsbeauftragte/${props.betriebsbeauftragterId}`
            )
            dialog.value = false
        }
    }

    const handleAbort = async () => {
        setInitialValues()
        dialog.value = false
    }
</script>

<template>
    <v-dialog v-if="betriebsbeauftragter" v-model="dialog" max-width="600">
        <template v-slot:activator="{ props: activatorProps }">
            <v-btn
                v-bind="activatorProps"
                append-icon="mdi-pencil"
                variant="text"
                class="text-none"
                >{{ betriebsbeauftragter.fullName }}</v-btn
            >
        </template>
        <v-card prepend-icon="mdi-calendar" title="Kontaktdaten des Betriebsbeauftragten ändern">
            <v-card-text>{{ betriebsbeauftragter.fullName }}</v-card-text>
            <v-card-title> Mobil: </v-card-title>
            <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="12" sm="12"
                        ><v-text-field
                            v-model="kontakt.email[0]"
                            type="email"
                            placeholder="vorname.nachname@example.de"
                            label="E-Mail"
                        /><v-text-field
                            v-model="kontakt.mobil[0]"
                            type="tel"
                            placeholder="0175 123 345 6789"
                            label="Mobil"
                        />
                        <v-text-field
                            v-model="kontakt.telefon[0]"
                            type="tel"
                            placeholder="0123 45 67 890"
                            label="Festnetz"
                        />
                    </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" md="12" sm="12">{{ kontakt }} </v-col>
                </v-row>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn text="Abbrechen" variant="plain" @click="handleAbort"></v-btn>

                <v-btn
                    color="primary"
                    text="Speichern"
                    variant="tonal"
                    :disabled="isSubmitDisabled"
                    @click="handleChangeKontaktSubmit"
                ></v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style scoped></style>
